.headerDiv {
  margin: 30px 0 30px 0;
  width: 80vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;

  font-family: 'Manrope', sans-serif;
  font-size: 75px;
  font-weight: 1000;

  --bg-size: 400%;
  /* --color-one: hsl(15 90% 55%); */
  --color-one: #0799ee;
  /* --color-two: hsl(40 95% 55%); */
  --color-two: #f606ee;
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 8s infinite linear;
}

.menuItems {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
}

.allFilters {
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 10px 20px 20px 20px;
}

.yearFilters {
  margin: 10px 0 10px 0;
  width: 320px;
  display: flex;
  justify-content: space-around;
  height: fit-content;
}

.menuButton {
  border: none;
  flex-grow: 0.6;
  max-width: 20%;
  transition: 0.3s;
  font-family: 'Manrope', sans-serif;
  font-weight: 900;
  align-content: center;
  width: fit-content;
  vertical-align: middle;
  padding: 10px 0 10px 0;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
}

.yearFilterButton {
  border: none;
  flex-grow: 0.6;
  max-width: 20%;
  transition: 0.3s;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  align-content: center;
  width: fit-content;
  vertical-align: middle;
  padding: 10px 0 10px 0;
  cursor: pointer;
  background-color: transparent;
}

.yearFilterButtonSelectedYear {
  background-color: #0799ee;
  color: white;
}

.menuButton:hover {
  box-shadow: inset -8px -8px 8px 0 rgba(174, 174, 192, 0.25),
    inset 8px 8px 8px 0 #fff, -8px -8px 24px 0 #fff,
    8px 8px 24px 0 rgba(174, 174, 192, 0.4);
  border-radius: 30px;
}

.menuButton:active {
  box-shadow: inset -8px -8px 8px 0 rgba(255, 255, 255, 0.7),
    inset 8px 8px 8px 0 rgba(174, 174, 192, 0.897);
  border-radius: 30px;
}

.guessesTotalCount {
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
  padding-top: 3px;
  text-align: center;
}

.gameSuspendedNotice {
  font-size: 15px;
  font-style: italic;
  font-weight: 600;
  padding-top: 3px;
  text-align: center;
  margin-bottom: 25px;
  width: 80%;
  max-width: 500px;
  border-radius: 15px;
  background-color: #43ad293b;
  padding: 15px 10px 15px 10px;
}

@media only screen and (max-width: 800px) {
  .title {
    font-size: 45px;
    font-weight: 800;
  }

  .menuItems {
    flex-wrap: wrap;
    width: 80%;
  }

  .menuButton {
    font-size: 12px;
  }
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}
