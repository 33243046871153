.App {
  display: flex;
  justify-content: center;
  background: #f0f0f3;
  /* width: 100vw !important;
  height: 100vw !important; */
}

.Main {
  margin-top: 50px;
  width: 95vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* width: 95vw; */
  background: #f0f0f3;
  /* flex-grow: 1; */
  padding-bottom: 50px;
  font-family: 'Manrope', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
}

.dropShadow1 {
  -moz-box-shadow: -8px -8px 24px 0 #fff,
    8px 8px 24px 0 rgba(174, 174, 192, 0.4);
  -webkit-box-shadow: -8px -8px 24px 0 #fff,
    8px 8px 24px 0 rgba(174, 174, 192, 0.4);
  box-shadow: -8px -8px 24px 0 #fff, 8px 8px 24px 0 rgba(174, 174, 192, 0.4);
  border-radius: 50px;
}

.dropShadow2 {
  -moz-box-shadow: inset -8px -8px 8px 0 rgba(25, 25, 233, 0.096),
    inset 8px 8px 8px 0 #fff, -8px -8px 24px 0 #fff,
    8px 8px 24px 0 rgba(41, 14, 10, 0.774);
  -webkit-box-shadow: inset -8px -8px 8px 0 rgba(174, 174, 192, 0.11),
    inset 8px 8px 8px 0 #d5d9eb31, -8px -8px 24px 0 #fff,
    8px 8px 24px 0 rgba(32, 10, 10, 0.829);
  box-shadow: inset -8px -8px 8px 0 rgba(6, 6, 252, 0.096),
    inset 8px 8px 8px 0 #e6e8f028, -8px -8px 24px 0 #d8dce454,
    8px 8px 24px 0 rgba(25, 25, 53, 0.274);
  border-radius: 30px;
}

.buttonActiveSelection {
  -moz-box-shadow: inset -8px -8px 8px 0 rgba(255, 255, 255, 0.7),
    inset 8px 8px 8px 0 rgba(174, 174, 192, 0.2);
  -webkit-box-shadow: inset -8px -8px 8px 0 rgba(255, 255, 255, 0.7),
    inset 8px 8px 8px 0 rgba(174, 174, 192, 0.2);
  box-shadow: inset -8px -8px 8px 0 rgba(255, 255, 255, 0.7),
    inset 8px 8px 8px 0 rgba(174, 174, 192, 0.2);
  border-radius: 30px;
}

@media only screen and (max-width: 800px) {
  .Main {
    margin-top: 10px;
  }
}
