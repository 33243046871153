.quickFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0 10px 0;
  width: 320px;
  display: flex;
}

.quickFilterInput {
  width: 60%;
  margin-left: 8px;
  border-radius: 50px;
  border-radius: 10px;
  border: 3px solid;
  border-color: rgba(128, 128, 128, 0.515);
  transition: border-color 1s ease-in-out;
  padding-left: 5px;
  font-family: inherit;
  justify-self: center;
}

.quickFilterInput:focus {
  border: 3px solid;
  border-color: rgba(205, 242, 34);
}
